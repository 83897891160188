import { siteData } from '@/data/site';

const SwapWidget = () => {
  return (
    <iframe
      title='Swap Widget'
      className='h-full w-full rounded-[1.125rem] border-none'
      src={siteData.swapWidgetUrl}
    />
  );
};

export default SwapWidget;
