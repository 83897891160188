import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

export const IndexBlogCard = (params) => {
  return (
    <AnimateWhileInView
      key={params.index}
      delay={0.2 + 0.1 * parseInt(params.index)}
      classNaming='blog-card relative mx-auto my-8 flex w-full flex-col rounded-[14px] bg-[#0E1E2B] text-left transition-all duration-300 hover:-translate-y-4 md:w-[480px] lg:mx-0 lg:my-0 lg:w-full'
    >
      <Link href={params.href} aria-label={params.title} className='relative w-full'>
        <div className='relative w-full pb-[56.25%]'>
          <ExportedImage
            loading='lazy'
            width={params.feature_image_info.width}
            height={params.feature_image_info.height}
            src={params.feature_image_info.url}
            alt={params.feature_image_info.alt || params.title || 'Image on Komodo Platform'}
            className='absolute left-0 top-0 h-full w-full rounded-t-[18px] object-cover shadow-sm'
          />
        </div>
      </Link>
      <Link href={params.href} className=''>
        <div className='mt-4 px-4'>
          <p className='mt-2 text-base leading-[20px] text-[#60A5FA]'>{params.tagName}</p>
          <h2 className='leading-1 mt-2 font-heading text-base font-semibold'>{params.title}</h2>
          <p className='my-2 text-sm font-normal leading-[19px]'>{params.description}</p>
        </div>
      </Link>
      <div className='h-[45px] w-full'></div>
      <div className='absolute bottom-0 flex w-full flex-row justify-between px-4 pb-4'>
        <Link href={params.href}>
          <p className='text-sm font-bold leading-[28px]'>{params.readingTime}</p>
        </Link>
        <Link href={params.href}>
          <p className='text-sm font-bold leading-[28px]'>
            <time dateTime={params.dateIso}>{params.date}</time>
          </p>
        </Link>
      </div>
    </AnimateWhileInView>
  );
};
